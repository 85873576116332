export type SiteConfig = typeof siteConfig
export const siteConfig = {
    name: "Homicipher",
    slogan: "slogan",
    icon: "/favicon.ico",
    appleIcon: "/apple-touch-icon.png",
    gameIframe: "https://www.youtube.com/embed/0LP87Gu6UJE?si=PtiQamDtzGHgxxJe",
    domain: "homicipher.com",
    gaId: "G-X6PWTDSPXN",
    navbarItems: [
        {
            title: "title",
             href: "/",
        }
    ],
}
